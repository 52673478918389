import { Grid, Typography, Card, Button, Link, Spinner, Icon } from '@platform-ui/design-system';
import React, { FC, useEffect, useState } from 'react';
import Connect from '../../../../Connect/Connect';

interface PackageLinkProps {
  packageLink?: string;
  showAlertIcon: (tabName: string, icon: string, tooltip: string) => void;
}

interface packageVersionData {
  tenantId: string;
  z360Version: string;
  zquVersion: string;
}

export const PackageLink: FC<PackageLinkProps> = ({
  packageLink,
  showAlertIcon
}: PackageLinkProps) => {

  const [packageVersion, setPackageVersion] = useState<packageVersionData>(null);
  const [loading, setLoading] = useState(true);
  const [showCPQ, setShowCPQ] = useState(false);

  const connect: Connect = (window as any).connect;
  const tenantId = (connect.tenant as any).tenant_id;

  const knowledgeCenterSalesforceCRM = 'https://knowledgecenter.zuora.com/Zuora_Central_Platform/Zuora_Connector_for_Salesforce_CRM/2_Getting_Started_with_Zuora_Data_Sync_for_Salesforce_CRM/1_Zuora_Data_Sync_for_Salesforce_CRM_Installation'

  //TODO (Anan): change this hard coded version to something adjustable from outside
  const latestSalesforceVersion = '5.18';
  const latestCPQVersion = '10.29.2';

  const openKnowledgeCenterLink = () => {
    window.open(knowledgeCenterSalesforceCRM, '_blank');
  }

  const getCurrentPackageVersion = async () => {
    try {
      // This is for LocalHoat testing only
      if (window.location.host.includes("local")) {
        setLoading(false);
        return { tenantId: '1919', z360Version: "5.1", zquVersion: '1.1', ttl: 3600 };
      }

      const endpoint = `/platform/gateway-proxy-v2/datacon/package/versions/tenants/${tenantId}`;
      const request: RequestInit = {
        method: 'GET',
        headers: {
          'Zuora-Tenant-Id': tenantId,
          'Content-Type': 'application/json',
        },
      };

      const response = await fetch(endpoint, request);

      if (!response.ok) {
        Connect.log(`HTTP error! Status: ${response.status}`);
        return;
      }

      const data = await response.json();
      setLoading(false);
      return data;
    } catch (error) {
      setLoading(false);
      Connect.log(`Fetch error: ${error}`);
      return;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data: packageVersionData = await getCurrentPackageVersion();
        setPackageVersion(data);
        if (data.z360Version !== latestSalesforceVersion) {
          showAlertIcon('Package Link', 'info', 'New Package Version Available');
        }
        if (data.zquVersion && data.zquVersion !== '0.0') {
          setShowCPQ(true);
        }
      } catch (error) {
        Connect.log(`Error fetching package version: ${error}`);
      }
    };
    fetchData();
  }, []);
  
  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sx={{ marginBottom: '20px' }}>
        {/* Left Top Card */}
        <Card
          id='salesforce-package-tab'
          header='Zuora 360 Sync Package'
          titleBar
          body={
            <Grid container direction='column'>
              <Grid item>
                <Typography>
                  {`Latest Package Version: ${latestSalesforceVersion}`}
                </Typography>
              </Grid>
              <Grid item>
                {loading ? <Spinner/> :
                  <Typography body={
                    <Grid container direction='row'>
                      <Grid item>
                        <Typography>
                          {packageVersion?.z360Version && `Version Currently Installed: ${packageVersion.z360Version}`}
                        </Typography>
                      </Grid>
                      {packageVersion?.z360Version !== latestSalesforceVersion && 
                      <Grid item>
                        <Icon body='warning' fontSize='small' color='warning' tooltip='New Salesforce package version available'/>
                      </Grid>}
                    </Grid>
                  } />
                }
              </Grid>
              <Grid item xs={3} alignContent='flex-start'>
                <Button variant="contained" dsOnClick={() => {window.open("https://test.salesforce.com/packaging/exportPackageDetail.apexp?p1=04t8Z000000Ug1D", '_blank')}}>
                  Download Latest Package for Sandbox
                </Button>
              </Grid>
              <Grid item xs={3} alignContent='flex-start'>
                <Button variant="contained" dsOnClick={() => {window.open("https://login.salesforce.com/packaging/exportPackageDetail.apexp?p1=04t8Z000000Ug1D", '_blank')}}>
                  Download Latest Package for Production
                </Button>
              </Grid>
            </Grid>
          }
        />
        <Grid item xs={12} sx={{ height: '20px' }}></Grid>
        {/* Left Bottom Card */}
        { showCPQ && <Card
          id='zuora-cpq-card'
          header='Zuora Quotes Package'
          titleBar
          body={
            <Grid container direction='column'>
              <Grid item>
                <Typography variant='body2'>
                  Note: Zuora CPQ package must be installed to sync Product Catalog related objects
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {`Latest Package Version: ${latestCPQVersion}`}
                </Typography>
              </Grid>
              <Grid item>
                {loading ? <Spinner/> :
                  <Typography body={
                    <Grid container direction='row'>
                      <Grid item>
                        <Typography>
                          {packageVersion?.zquVersion && `Version Currently Installed: ${packageVersion.zquVersion}`}
                        </Typography>
                      </Grid>
                      {packageVersion?.zquVersion !== latestCPQVersion && 
                      <Grid item>
                        <Icon body='warning' fontSize='small' color='warning' tooltip='New Salesforce CPQ package version available'/>
                      </Grid>}
                    </Grid>
                  } />
                }
              </Grid>
              <Grid item xs={3} alignContent='flex-start'>
                <Button variant="contained" dsOnClick={() => {window.open("https://test.salesforce.com/packaging/installPackage.apexp?p0=04tHu000003tefa", '_blank');}}>
                  Download Latest Package for Sandbox
                </Button>
              </Grid>
              <Grid item xs={3} alignContent='flex-start'>
                <Button variant="contained" dsOnClick={() => {window.open("https://login.salesforce.com/packaging/installPackage.apexp?p0=04tHu000003tefa", '_blank');}}>
                  Download Latest Package for Production
                </Button>
              </Grid>
            </Grid>
          }
        />}
      </Grid>
      <Grid item xs={6}>
        {/* Right Card */}
        <Card
          id='installation-instructions-tab'
          header='Installation Instructions'
          titleBar
          body={
            <Grid container direction='column' spacing={1}>
              <Grid item>
                <Typography component='div'>
                  <Link variant='body1' underline='hover' dsOnClick={openKnowledgeCenterLink}>
                    Knowledge Center Instructions
                  </Link>
                  <ol>
                    <li>Navigate to the URL provided by Zuora and log in to your Salesforce org.</li>
                    <li>On the Package Installation Details page, review the information and click Continue.</li>
                    <li>When prompted to approve third-party access, select Yes to grant access and click Continue.</li>
                    <li>On the Approve Package API Access page, click Next.</li>
                    <li>
                      On the Choose Security Level page, select Select Security Settings to set the level of access that you want to provide for user groups. If you select Grant access to admins only, you must update your security settings in Salesforce to grant permissions to other users to be able to access Zuora Connector for Salesforce CRM.
                    </li>
                    <li>Set access level for each user profile.</li>
                    <li>Click Next.</li>
                    <li>On the Install Package page, click Install. The installation process starts, and you will receive an automated email when the process completes.</li>
                    <li>Select Zuora 360 Sync from the menu at the top right-hand corner to change to the Zuora 360 view. Now you are ready to configure the package.</li>
                  </ol>
                </Typography>
              </Grid>
            </Grid>
          }
        />
      </Grid>
    </Grid>
  );
}
