import styled from '@emotion/styled';

export const ContentPaddingStyle = styled.div`
  padding-top: 10px;
`;

export const ButtonSectionStyle = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const SnowflakeInputSectionStyle = styled.div`
  display: inline;
  margin-right: 20px;
`;

export const SnowflakeInputRowSectionStyle = styled.div`
  margin-top: 20px;
`;

export const TopMessageInProgress = styled.div`
  background-color: #f7d0d0;
  display: block;
  color: black;
  font-weight: bold;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 5px;
`;

export const TopMessageCompleted = styled.div`
  background-color: #b8f3b8;
  display: block;
  color: black;
  font-weight: bold;
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 5px;
`;