import React from "react";
import CustomTabExamplePage from "../../../Connectors/CustomTabs/CustomTabExamplePage";
import { Authentication, Execution, FieldMapping, SolutionInstances } from '../../../Connectors/CustomTabs/NetSuite';
import SyncSettings from '../../../Connectors/CustomTabs/ZDP/SyncSettings/SyncSettings';
import { PackageLink } from "../../../Connectors/CustomTabs/SalesforceCRM";
import { Icon } from "@platform-ui/design-system";
import ConnectionSettings from '../../../Connectors/CustomTabs/Snowflake/ConnectionSettings';
import { Authentication as ZDPAuthentication } from '../../../Connectors/CustomTabs/ZDP/Authentication/Authentication';
import {
  Authentication as WDAuthentication,
  Execution as WDExecution,
  FieldMapping as WDFieldMapping
} from '../../../Connectors/CustomTabs/Workday';
import {
  Authentication as WDBAuthentication,
  Execution as WDBExecution,
  FieldMapping as WDBFieldMapping
} from '../../../Connectors/CustomTabs/WorkdayBilling';
import ZdqRedshiftConnector from "../../../Connectors/CustomTabs/ZDQ/connectors/Redshift/ZdqRedshiftConnector";
import ZdqRedshiftConnectorStatus from "../../../Connectors/CustomTabs/ZDQ/connectors/Redshift/ZdqRedshiftConnectorStatus";
import ZdqBigQueryConnector from "../../../Connectors/CustomTabs/ZDQ/connectors/BigQuery/ZdqBigQueryConnector";
import ZdqBigQueryConnectorStatus from "../../../Connectors/CustomTabs/ZDQ/connectors/BigQuery/ZdqBigQueryConnectorStatus";
import ZdqS3Connector from "../../../Connectors/CustomTabs/ZDQ/connectors/S3/ZdqS3Connector";
import ZdqS3ConnectorStatus from "../../../Connectors/CustomTabs/ZDQ/connectors/S3/ZdqS3ConnectorStatus";

export interface CustomTabProps {
  name: string; // key string from the JSON payload
  tabDetails: object; // tabDetails contains all the values of the JSON payload set in ui_layout for your custom tab
  locale: string; // locale in Rails I18n format https://guides.rubyonrails.org/i18n.html
  lastUpdatedBy: string;
  lastUpdatedDate: string;
  tenantId: string; // Tenant id in Connect's database for calls to the backend, 32 digit uuid
  customIcon: (tabName: string, icon: string, tooltip: string) => void;
}

export const CustomTab = ({
  name,
  tabDetails,
  locale,
  lastUpdatedBy,
  lastUpdatedDate,
  tenantId,
  customIcon
}: CustomTabProps) => {
    
  function renderSwitch(name) {
    switch(name) {
      // When adding a new custom tab, create a case that matches the json key name of your desired tab
      // The check here is case sensitive!
      case 'custom':
        return <CustomTabExamplePage
          title={tabDetails['titleTranslations'][locale]['tabName']}
          description={tabDetails['descriptionTranslations'][locale]['description']}
          url={tabDetails['customUrl']}
          fieldGroups={tabDetails['fieldGroups']}
          lastUpdatedBy={lastUpdatedBy}
          lastUpdatedDate={lastUpdatedDate}
          tenantId={tenantId}
        />;
      // NetSuite ERP custom tabs: execution, solutionInstances, and fieldMapping
      case 'NetSuiteERP/execution':
        return <Execution tabName={'execution'} inbound={true} newLayout={tabDetails['newLayout']} />;
      case 'NetSuiteERP/solutionInstances':
        return <SolutionInstances tabName={'solutionInstances'} inbound={true} />;
      case 'NetSuiteERP/fieldMapping':
        return <FieldMapping tabName='fieldMapping' inbound={true} />;
      case 'NetSuiteERP/authentication':
      // NetSuite GL custom tabs: authentication, execution, solutionInstances, and fieldMapping
      case 'NetSuiteGL/authentication':
        return (
          <Authentication
            locale={locale}
            fieldGroups={(tabDetails as any).fieldGroups}
            lastUpdatedBy={lastUpdatedBy}
            lastUpdatedDate={lastUpdatedDate}
          />
        );
      case 'NetSuiteGL/execution':
        return <Execution tabName={'execution'} inbound={false} newLayout={tabDetails['newLayout']} />;
      case 'NetSuiteGL/solutionInstances':
        return <SolutionInstances tabName={'solutionInstances'} inbound={false} />;
      case 'NetSuiteGL/fieldMapping':
        return <FieldMapping tabName='fieldMapping' inbound={false} />;
      case 'Workday/authentication':
        return (
          <WDAuthentication
            locale={locale}
            fieldGroups={(tabDetails as any).fieldGroups}
            lastUpdatedBy={lastUpdatedBy}
            lastUpdatedDate={lastUpdatedDate}
          />
        );
      case 'Workday/execution':
        return <WDExecution tabName='execution' newLayout={tabDetails['newLayout']}  />;
      case 'Workday/fieldMapping':
        return <WDFieldMapping tabName='fieldMapping' />
      case 'ZDP/syncSettings':
        return <SyncSettings />;
      case 'ZDP/authentication':
        return <ZDPAuthentication />;
      case 'Salesforce/packageLink':
        return <PackageLink
          showAlertIcon={customIcon}
        />;
      case 'Snowflake/connectionSettings':
        return <ConnectionSettings />;
      case 'WorkdayBilling/authentication':
        return (
          <WDBAuthentication
            locale={locale}
            fieldGroups={(tabDetails as any).fieldGroups}
            lastUpdatedBy={lastUpdatedBy}
            lastUpdatedDate={lastUpdatedDate}
          />
        );
      case 'WorkdayBilling/execution':
        return <WDBExecution tabName='execution' newLayout={tabDetails['newLayout']}  />;
      case 'WorkdayBilling/fieldMapping':
        return <WDBFieldMapping tabName='fieldMapping' />
      case 'ZDQ/Redshift/setup':
        return <ZdqRedshiftConnector></ZdqRedshiftConnector>
      case 'ZDQ/Redshift/status':
        return <ZdqRedshiftConnectorStatus></ZdqRedshiftConnectorStatus>
      case 'ZDQ/BigQuery/setup':
        return <ZdqBigQueryConnector></ZdqBigQueryConnector>
      case 'ZDQ/BigQuery/status':
        return <ZdqBigQueryConnectorStatus></ZdqBigQueryConnectorStatus>
      case 'ZDQ/S3/setup':
        return <ZdqS3Connector></ZdqS3Connector>
      case 'ZDQ/S3/status':
        return <ZdqS3ConnectorStatus></ZdqS3ConnectorStatus>
      default:
        return <CustomTabExamplePage missingName={true} />;
    }
  }

  return (
    <>{ renderSwitch(name) }</>
  );
}

export default CustomTab