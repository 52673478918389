import { Box, Grid } from "@mui/material"
import { Card, Chart, ChartIntervals, Spinner, Typography, designTokens } from "@platform-ui/design-system"
import React, { Dispatch, useCallback, useEffect, useMemo, useState } from "react"
import { ExistingDestination, useGetTransfers } from "@prequel/react";
import { DataItem } from "@platform-ui/design-system/dist/types/Chart";
import { Action, useStoreContext } from "../../../../Store";
import { IADState } from "../../../../IntegrationApps/IntegrationAppDetails/state";
import fetchAuthTokenWithExistingDestination from "../hooks/fetchAuthTokenWithExistingDestination";
import { AUTH_TOKEN_URL, PREQUEL_API_HOST, T9_PERMISSION_MAPPINGS } from "../Constants";
import Connect from "../../../../Connect/Connect";
import MetricsInfoCard from "./MetricsInfoCard";
import { useIdentity } from "../../../../IdentityProvider";
import { IdentityPermissions } from "../../../../IdentityProvider/constants/permissions";
import { ZDQ_DESTINATION_DATA, ZDQ_RECIPIENT } from "../action_types";
import _ from 'lodash';

interface TransferData {
    day: string,
    total_rows_transferred: number,
    total_transfers_completed: number,
    total_volume_transferred_in_mb: number
}

function ZdqConnectorStatus({ vendor }) {

    // States
    const [chartData, setChartData] = useState<DataItem[]>([]);
    const [totalRowsSynced, setTotalRowsSynced] = useState<string>('0');
    const [showSpinner, setShowSpinner] = useState(false);
    const [lastTransfer, setLastTransfer] = useState(null);

    // Context
    // const { recipient, destinationData, lastTransfer, setLastTransfer } = useConnectorContext();
    const { state } = useStoreContext() as { state: IADState, dispatch: Dispatch<Action> };
    const destinationData = state.settingsHash[ZDQ_DESTINATION_DATA];
    const recipient = state.settingsHash[ZDQ_RECIPIENT];
    const fetchAuthTokenUrl = `${state.settingsUrl.replace('.json', AUTH_TOKEN_URL)}`;

    // T9 Permissions
    const { hasPermission } = useIdentity();
    const hasPermissionsEnabled = hasPermission(`permission.${T9_PERMISSION_MAPPINGS[vendor]}` as IdentityPermissions)

    const getNextTransferTime = (() => {
        if (destinationData?.frequency_minutes && (lastTransfer?.started_at || lastTransfer?.submitted_at)) {
            const lastTransferAttempt = new Date(lastTransfer.started_at || lastTransfer.submitted_at);
            const currentTime = new Date();
            const frequency = destinationData?.frequency_minutes / 60;
            const nextTransferTime = Math.abs(frequency - (currentTime.getTime() - lastTransferAttempt.getTime()) / (1000 * 60 * 60))
            if (nextTransferTime > frequency) { return 'In about unknown hours' }
            return 'In about ' + (nextTransferTime < 1 ? nextTransferTime * 60 : nextTransferTime).toFixed(2) + `${nextTransferTime < 1 ? ' minutes' : ' hours'}`;
        }
        return 'In about unknown hours'
    })
    const gridItems = useMemo(
        () => [
            { id: '1', title: 'Last successful Transfer Completed', tooltip: 'This timestamp marks the completion of the most recent successful data transfer to your destination. It reflects the latest data available in your system.', data: destinationData?.last_successful_transfer_ended_at || '' },
            { id: '2', title: 'Next Scheduled Transfer Starts', tooltip: 'This timestamp indicates when the next synchronization process will commence', data: getNextTransferTime() },
            { id: '3', title: 'Total Rows Synced (last 30 days)', tooltip: 'This indicates the total number of rows synchronized during the last 30 days. It provides insight into the recent data transfer activity for the connector.', data: '' }
        ],
        [destinationData, lastTransfer]
    );

    // Transfer Hook
    const fetchAuthTokenWithDestinationWrapper = useCallback(async (existingDestination: ExistingDestination) => {
        return await fetchAuthTokenWithExistingDestination(fetchAuthTokenUrl, existingDestination);
    }, []);
    const getTransfers = useGetTransfers(fetchAuthTokenWithDestinationWrapper, window.location.host, PREQUEL_API_HOST);

    // All transfers to display the chart
    async function getAllTransfers() {
        try {
            const csrf = document.querySelector('meta[name=\'csrf-token\']').getAttribute('content');
            const options = {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-CSRF-Token': csrf
                })
            };
            const response = await window.fetch(`${state.settingsUrl.replace('.json', `/zdq/transfers?destination_id=${destinationData?.id}`)}`, options);
            const data: TransferData[] = await response.json();
            if (!response.ok) {
                throw Error(data['message'] || 'Error while fetching the status for the destination!');
            }

            // TODO: This is done because the dates shown in the chart are not the dates that are fetched (+,- 1 day on both ends). Check if T07:00:00.000Z for all locations? I think it works fine for 
            // only the US location and might not work for other locations.
            setChartData(data?.map((data: TransferData) => { return { ...data, x: data.day + "T07:00:00.000Z", y: data.total_rows_transferred } }));
            const temp = data.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.total_rows_transferred;
            }, 0)
            setTotalRowsSynced(temp.toLocaleString());

        } catch (error) {
            Connect.log(error);
        }
    }

    useEffect(() => {
        const fetchLastTransfer = async () => {
            try {
                const response = await getTransfers(destinationData, { count: 1 })
                if (response.status === 'error' || response.data?.transfers?.length !== 1) {
                    throw Error(response.message || "Couldn't fetch a single last transfer");
                }

                if (response.status === 'success') {
                    setLastTransfer(response.data.transfers[0]);

                    return;
                }
                Connect.log('Response status is neither success nor error while fetching last transfer.', response);
            } catch (error) {
                Connect.log('Failed to fetch last transfer', error);
            }
        }

        if (localStorage.getItem("bypassPermissions") || hasPermissionsEnabled && recipient && !_.isEmpty(destinationData)) {
            try {
                setShowSpinner(true);
                getAllTransfers();
                fetchLastTransfer();
            } catch (e) {
                Connect.log(e)
            } finally {
                setShowSpinner(false);
            }
        }
    }, [recipient, destinationData])


    if (!localStorage.getItem('bypassPermissions') && !hasPermission(`permission.${T9_PERMISSION_MAPPINGS[vendor]}` as IdentityPermissions)) {
        return <Card autoDistance id="disabled-tenant-card" body={
            <Typography color={designTokens.colors.coolGray300}
                sx={{ marginTop: '5%', marginBottom: '5%', textAlign: 'center' }}
                variant="body1"
                body="You don't have permissions enabled to view this tab. Please contact Zuora support if you are seeing this message." />}
        />
    }

    if (showSpinner || destinationData === undefined) {
        return <Spinner e2e="connector-status-spinner" size="large"></Spinner>
    }

    if (!recipient) {
        return <Card autoDistance id="connector-setup-no-recipient-or-models" body={
            <Typography color={designTokens.colors.coolGray300}
                sx={{ marginTop: '5%', marginBottom: '5%', textAlign: 'center' }}
                variant="body1"
                body="Error while fetching the recipient details for this tenant. Please contact Zuora support if you are seeing this message." />
        }
        />
    }

    if (!_.isEmpty(destinationData)) {
        return <Box>
            <Card autoDistance id='connector-status-metrics'
                header='Connector Metrics' titleBar
                noPaddingBottom
                body={
                    <Grid container direction='row'>
                        <Grid item xs={4}>
                            <MetricsInfoCard data={gridItems[0].data ? new Intl.DateTimeFormat('en-US', {
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric'
                            }).format(new Date(gridItems[0].data)) : 'No Transfer Completed Yet'} title={gridItems[0].title} tooltip={gridItems[0].tooltip} />
                        </Grid>
                        <Grid item xs={4}>
                            <MetricsInfoCard data={gridItems[1].data} title={gridItems[1].title} tooltip={gridItems[1].tooltip} ></MetricsInfoCard>
                        </Grid>
                        <Grid item xs={4}>
                            <MetricsInfoCard data={totalRowsSynced} title={gridItems[2].title} tooltip={gridItems[2].tooltip} ></MetricsInfoCard>
                        </Grid>
                    </Grid>
                }>
            </Card >
            <Card autoDistance
                id='connector-status-rows-synced'
                header='Rows Synced'
                titleBar
                noPaddingBottom
                body={
                    <Box sx={{ '> div': { fontSize: '1.5rem' } }}>
                        <Typography color={designTokens.colors.coolGray400}>Last 30 days</Typography>
                        <Chart
                            colorPalette="single"
                            type="bar"
                            data={chartData}
                            xInterval={ChartIntervals.DAY}
                            yAxisIntegerOnly
                            // locale='en-US'
                            showAllXAxisLabel
                            tooltip={(props) =>
                                <Grid container sx={{ margin: '10' }}>
                                    <Grid item>
                                        <Typography variant="title" body={chartData[props.currIndex]?.x ? new Intl.DateTimeFormat('en-US', {
                                            month: 'long',
                                            day: 'numeric',
                                            year: 'numeric'
                                        }).format(new Date(chartData[props.currIndex].x)) : 'Unknown'} />


                                    </Grid>
                                    <Grid container direction="row" justifyContent="space-between" sx={{ marginTop: '10%' }}>
                                        <Grid item><Typography variant="subtitle1">Rows Synced</Typography></Grid>
                                        <Grid item><Typography variant="subtitle1" body={chartData[props.currIndex].y || '0'} /></Grid>
                                    </Grid>
                                </Grid>
                            }
                        />
                    </Box>
                }
            >
            </Card>
        </Box >
    }
    return <Card autoDistance id="connector-status-no-destination" body={
        <Typography color={designTokens.colors.coolGray300} sx={{ marginTop: '5%', marginBottom: '5%', textAlign: 'center' }} variant="body1" body="No destination has been configured for this tenant. Please add a destination to see its status. If you have added a destination and still see this message, please contact zuora support."></Typography>} />
}

export default ZdqConnectorStatus