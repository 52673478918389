import { Accordion, DataSummary } from "@platform-ui/design-system"
import React from "react"
import BasicTemplate from "../../BasicTemplate/BasicTemplate"

// Example Payload used to generate this component
// {
// 	"custom": {
// 		"enabled": true,
// 		"customUrl": "https://www.google.com",
// 		"titleTranslations": {
// 			"en": {
// 				"tabName": "Custom Tab",
// 				"cardHeader": "Custom Tab"
// 			}
// 		},
// 		"descriptionTranslations": {
// 			"en": {
// 				"description": "A long description of the Custom Tab."
// 			}
// 		},
// 		"fieldGroups": [{
// 			"fields": [{
// 				"name": "select_field_name1",
// 				"required": true,
// 				"fieldType": "select",
// 				"titleTranslations": {
// 					"en": "Select Data Object"
// 				},
// 				"optionTranslations": {
// 					"en": [{
// 						"key": "data_object_option1",
// 						"value": "data_object_option1"
// 					}, {
// 						"key": "data_object_option2",
// 						"value": "data_object_option2"
// 					}, {
// 						"key": "data_object_option3",
// 						"value": "data_object_option3"
// 					}]
// 				},
// 				"descriptionTranslations": {
// 					"en": "Select Data Object"
// 				},
// 				"placeholderTranslations": {
// 					"en": "Placeholder"
// 				}
// 			}, {
// 				"name": "select_field_name2",
// 				"required": true,
// 				"fieldType": "select",
// 				"titleTranslations": {
// 					"en": "Choose Object for which you want to map the source and target fields"
// 				},
// 				"optionTranslations": {
// 					"en": [{
// 						"key": "choose_object_option1",
// 						"value": "choose_object_option1"
// 					}, {
// 						"key": "choose_object_option2",
// 						"value": "choose_object_option2"
// 					}, {
// 						"key": "choose_object_option3",
// 						"value": "choose_object_option3"
// 					}]
// 				},
// 				"descriptionTranslations": {
// 					"en": "Choose Object for which you want to map the source and target fields"
// 				},
// 				"placeholderTranslations": {
// 					"en": "Placeholder"
// 				}
// 			}]
// 		}]
// 	}
// }

function CustomTabExamplePage(props: any) {
  const missingName = props.missingName
  const title = props.title
  const description = props.description
  const url = props.url
  const fieldGroups = props.fieldGroups
  const lastUpdatedBy = props.lastUpdatedBy
  const lastUpdatedDate = props.lastUpdatedDate
  const tenantId = props.tenantId

  return (
    <>
      {missingName && <h1>Template Missing</h1>}
      {!missingName && <>
        <BasicTemplate id={tenantId} header={title} fieldGroups={fieldGroups} lastUpdatedBy={lastUpdatedBy} lastUpdatedDate={lastUpdatedDate}/>
        <Accordion
          defaultExpanded={true}
          header={tenantId}
          body={
            <DataSummary
              data={{
                accountNumber: 'A00013279',
                createdBy: 'Kevin Suer',
                crmAccountId: undefined,
                keyContact: 'Inigo Montoya',
                lastModified: '03/23/2020 1:35PM',
                modifiedBy: 'Kevin Suer',
                status: 'Active'
              }}
              summary={[
                {
                  dataKey: 'accountNumber',
                  label: 'Account Number',
                  labelTooltip: 'A unique identifier',
                  type: 'string'
                },
                {
                  dataKey: 'crmAccountId',
                  label: 'CRM Account ID',
                  labelTooltip: 'Customer Relationship Management Account ID'
                },
                {
                  dataKey: 'status',
                  label: 'Status'
                },
                {
                  dataKey: 'lastModified',
                  label: 'Last Modified'
                },
                {
                  dataKey: 'modifiedBy',
                  label: 'Modified By'
                },
                {
                  dataKey: 'createdBy',
                  label: 'Created By'
                },
                {
                  dataKey: 'keyContact',
                  label: 'Key Contact'
                }
              ]}
            />
          }
          footer={description}
        />
      </>}
    </>
  )
}

export default CustomTabExamplePage